import styled from "styled-components"
import { addPropertyControls, ControlType } from "framer"

export default function Hexagon(props) {
    const Component = styled.div`
        width: 176px;
        height: 151px;
        flex-shrink: 0;
        fill: rgba(255, 255, 255, 0.04);
        stroke-width: 1px;
        stroke: rgba(225, 246, 255, 0.30);
        box-shadow: 0px 30px 30px 0px rgba(255, 255, 255, 0.05) inset;
        backdrop-filter: blur(10px);
        background: ${props.background};
        clip-path: path('M171.095 70.4863C172.892 73.5872 172.892 77.4128 171.095 80.5137L133.139 146.014C131.351 149.1 128.054 151 124.487 151H48.5129C44.9459 151 41.649 149.1 39.8606 146.014L1.90532 80.5137C0.108386 77.4128 0.108386 73.5872 1.90532 70.4863L39.8606 4.98625C41.649 1.89999 44.9459 0 48.5129 0H124.487C128.054 0 131.351 1.89999 133.139 4.98625L171.095 70.4863Z');
    `

    return <Component />
}

addPropertyControls(Hexagon, {
    background: {
        type: ControlType.Color,
        defaultValue: "#fff",
    },
})
